<template>
  <div class="document mt-16 pa-3 pa-md-16">
    <div v-if="type === 'informativa-clienti-en'">
      <h4>Information upon processing of personal data of customers</h4>
      <p><br></p>
      <p>This information is provided pursuant to article 13 of European Regulation no. 2016/679 (hereinafter, GDPR). According to this legislation, the data processing will be based on principles of propriety, lawfulness, transparency and protection of your privacy and your rights. Pursuant to the aforementioned article, the following information is provided:&nbsp;</p>
      <p>1. The data controller is <span class="bold">STYLEM ITALIA S.R.L.</span> (later, controller), with registered office in Prato (PO), via delle Fonti, 424 – VAT number 02266840970 – phone number +39 0574870718.&nbsp;</p>
      <p>2. The data you provide will be used for the following purposes:</p>
      <p>1) Purpose of providing our products and services. It also includes the satisfaction of needs connected with or instrumental to our business, including operational, management and customer assistance needs. The lawfulness of processing lies in the necessity of processing your data for the performance of a contract with the controller to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract with the controller (e.g. request for a quote). &nbsp;</p>
      <p>2) Administrative purpose. The controller will also process your data for compliance with legal obligations of tax, accounting and administrative nature to which the controller is subject (lawfulness of processing).&nbsp;</p>
      <p>3. Data communication is mandatory for the purposes related to the performance of pre-contractual or contractual measures. It is functional to benefit from the services requested, therefore any refusal to provide them implies the impossibility for the controller to perform such services.</p>
      <p>4. The recipients of the processing are the data processors (including third parties in agreement with the controller) and the persons within the organization and authorized by the controller. Personal data will not be disclosed, but may be shared with third parties (the list of data processors is available upon request to the controller), exclusively for technical and operational needs strictly related to the purposes set out above. In particular, with: companies or professional figures for the execution of accounting and/or tax duties; IT companies for the management of IT systems; software supplier companies.&nbsp;</p>
      <p>5. The data are processed and stored at the registered office of the controller. The servers are located within the mentioned office and within the European Union. However, please note that the controller uses the Gmail service, offered by the company Google inc., with registered office in the United States. This company offers a series of compliant international data transfer mechanisms, certified according to the EU-US framework, which constitutes a legal system for transferring personal data from the EEA to the United States. Google inc. guarantees to provide a high standard level of data protection, according to GDPR.</p>
      <p>6. Personal data will be kept for the time necessary to fulfil the aforementioned purposes, and, in any case, in compliance with current legislation.&nbsp;</p>
      <p>7. &nbsp;You can, at any time: access your personal data; obtain the correction or cancellation of them or the limitation of their processing; oppose the processing; exercise the right to the portability of your data; withdraw consent; lodge a complaint to the supervisory authority (Italian personal data protection Authority &ndash; Garante Privacy). You can exercise your rights by contacting the controller at the aforementioned phone number. <span style="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
      <p>The Data Controller</p>
      <p><span class="bold">STYLEM ITALIA S.R.L.</span></p>
    </div>
    <div v-if="type === 'informativa-clienti-it'">
      <h4>Informativa per il trattamento dei dati personali dei Clienti</h4>
      <p><br></p>
      <p>La presente informativa &egrave; resa ai sensi dell&rsquo;art. 13 del Regolamento europeo n. 679/2016 (in seguito, GDPR) e del d.lgs. 196/2003, cos&igrave; come modificato dal d.lgs. 101/2018. Secondo la normativa indicata, tale trattamento sar&agrave; improntato ai principi di correttezza, liceit&agrave;, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti. Ai sensi dei predetti articoli, si forniscono le seguenti informazioni:&nbsp;</p>
      <p>1. Il Titolare del trattamento &egrave; <span class="bold">STYLEM ITALIA S.R.L.</span> &nbsp;(in seguito, &ldquo;Titolare&rdquo;), con sede legale in Prato (PO) - 59100, via Delle Fonti, 424 – tel. +39 0574870718.&nbsp;</p>
      <p>2. Il trattamento dei Suoi dati avverr&agrave; per le seguenti finalit&agrave;:</p>
      <p>1) Finalit&agrave; di erogazione dei nostri prodotti e servizi. Comprende anche la soddisfazione di esigenze connesse o strumentali alla nostra attivit&agrave;, ivi comprese esigenze di tipo operativo, gestionale e di assistenza alla clientela. La base giuridica risiede pertanto nella necessit&agrave; del trattamento dei Suoi dati ai fini dell&rsquo;esecuzione di misure precontrattuali (esempio: richiesta di preventivo), preordinate alla conclusione di un eventuale contratto con il Titolare, e nell&rsquo;esecuzione del contratto avente ad oggetto i prodotti e servizi richiesti al Titolare.&nbsp;</p>
      <p>2) Finalit&agrave; amministrativa. Il Titolare tratter&agrave; i Suoi dati altres&igrave; in adempimento di obblighi di legge (base giuridica del trattamento), di natura fiscale, contabile ed amministrativa, cui &egrave; soggetto.</p>
      <p>3. Il conferimento dei dati per le finalit&agrave; legate all&rsquo;esecuzione di misure precontrattuali o del contratto &egrave; funzionale a usufruire dei servizi richiesti, pertanto l&rsquo;eventuale rifiuto di conferirli comporta l&rsquo;impossibilit&agrave; per il Titolare di eseguire tali servizi.&nbsp;</p>
      <p>4. I destinatari del trattamento sono i responsabili del trattamento (tra cui soggetti terzi al titolare) e le persone interne all&rsquo;organizzazione del Titolare, dietro autorizzazione dello stesso. I dati personali non saranno oggetto di diffusione, ma potranno essere comunicati a terzi, anche nominati Responsabili del trattamento (il cui elenco &egrave; a disposizione contattando il Titolare all&rsquo;indirizzo in apertura), &nbsp;esclusivamente per esigenze tecniche e operative strettamente collegate alle finalit&agrave; sopra enunciate. In particolare, a societ&agrave; o professionisti per l&rsquo;evasione di incombenze contabili e/o fiscali; societ&agrave; informatiche per la gestione dei sistemi informatici; societ&agrave; fornitrici di software.&nbsp;</p>
      <p>5. I dati sono trattati e conservati presso la sede legale del Titolare e su server ubicati all&rsquo;interno della sede legale del Titolare e, comunque, all&rsquo;interno dell&rsquo;Unione Europea. Tuttavia, si fa presente che il Titolare si avvale del servizio Gmail, offerto dalla societ&agrave; Google inc., con sede legale negli Stati Uniti. Tale societ&agrave; offre una serie di meccanismi per il trasferimento di dati a livello internazionale e dispongono della certificazione ai sensi dei framework EU - U.S., costituente un sistema legale per il trasferimento dei dati personali dal SEE agli Stati Uniti, all&apos;interno del quale le organizzazioni certificate garantiscono di fornire un livello di protezione in linea con la normativa europea per la protezione dei dati.</p>
      <p>6. I dati personali saranno conservati per il tempo necessario ad adempiere alle finalit&agrave; di cui sopra, e in ogni caso, in conformit&agrave; alla normativa vigente.&nbsp;</p>
      <p>7. &nbsp;Lei potr&agrave;, in qualsiasi momento: accedere ai Suoi dati personali; ottenere la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano; opporsi al trattamento; esercitare il diritto alla portabilit&agrave; dei Suoi dati; revocare il consenso; proporre reclamo all&rsquo;Autorit&agrave; di controllo (Garante per la protezione dei dati personali &ndash; Garante Privacy). L&rsquo;esercizio dei Suoi diritti potr&agrave; avvenire attraverso richiesta al numero di telefono riportato in apertura.</p>
      <p><span style="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
      <p>Il Titolare del trattamento</p>
      <p><span class="bold">STYLEM ITALIA S.R.L.&nbsp;</span></p>
      <p><br></p>
    </div>
    <div v-if="type === 'informativa-fornitori-en'">
      <h4>Information upon processing of personal data of suppliers</h4>
      <p><br></p>
      <p>This information is provided pursuant to article 13 of European Regulation no. 2016/679 (hereinafter, GDPR). According to this legislation, the data processing will be based on principles of propriety, lawfulness, transparency and protection of your privacy and your rights. Pursuant to the aforementioned article, the following information is provided:&nbsp;</p>
      <p>1. The data controller is <span class="bold">STYLEM ITALIA S.R.L.</span> (later, controller), with registered office in Prato (PO), via delle Fonti, 424 –VAT number 02266840970 – phone number +39 0574870718.&nbsp;</p>
      <p>2. The data you provide will be used for the following purposes:</p>
      <p>1) Purpose of performing the contract with the controller or taking steps at the request of the data subject prior to entering into a contract. The lawfulness of processing lies in the necessity of processing your data for the performance of a contract with the controller to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract with the controller (e.g. completing an order). &nbsp;</p>
      <p>2) Administrative purpose. The controller will also process your data for compliance with legal obligations of tax, accounting and administrative nature to which the controller is subject (lawfulness of processing).&nbsp;</p>
      <p>3. Data communication is mandatory for the purposes related to the performance of pre-contractual or contractual measures. Any refusal to provide them implies the impossibility for the controller to perform such services.</p>
      <p>4. The recipients of the processing are the data processors (including third parties in agreement with the controller) and the persons within the organization and authorized by the controller. Personal data will not be disclosed, but may be shared with third parties (the list of data processors is available upon request to the controller), exclusively for technical and operational needs strictly related to the purposes set out above. In particular, with: companies or professional figures for the execution of accounting and/or tax duties; IT companies for the management of IT systems; software supplier companies.&nbsp;</p>
      <p>5. The data are processed and stored at the registered office of the controller. The servers are located within the mentioned office and within the European Union. However, please note that the controller uses the Gmail service, offered by the company Google inc., with registered office in the United States. This company offers a series of compliant international data transfer mechanisms, certified according to the EU-US framework, which constitutes a legal system for transferring personal data from the EEA to the United States. Google inc. guarantees to provide a high standard level of data protection, according to GDPR.</p>
      <p>6. Personal data will be kept for the time necessary to fulfil the aforementioned purposes, and, in any case, in compliance with current legislation.&nbsp;</p>
      <p>7. &nbsp;You can, at any time: access your personal data; obtain the correction or cancellation of them or the limitation of their processing; oppose the processing; exercise the right to the portability of your data; withdraw consent; lodge a complaint to the supervisory authority (Italian personal data protection Authority &ndash; Garante Privacy). You can exercise your rights by contacting the controller at the aforementioned phone number.<span style="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
      <p><br></p>
      <p>The Data Controller</p>
      <p><span class="bold">STYLEM ITALIA S.R.L.</span></p>
      <p><br></p>
    </div>
    <div v-if="type === 'informativa-fornitori-it'">
      <h4>Informativa per il trattamento dei dati personali dei fornitori</h4>
      <p><br></p>
      <p>La presente informativa &egrave; resa ai sensi dell&rsquo;art. 13 del Regolamento europeo n. 679/2016 (in seguito, GDPR) e del d.lgs. 196/2003, cos&igrave; come modificato dal d.lgs. 101/2018.&nbsp;</p>
      <p>Tale trattamento sar&agrave; improntato ai principi di correttezza, liceit&agrave;, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.&nbsp;</p>
      <p>Ai sensi della normativa indicata, pertanto, si forniscono le seguenti informazioni:&nbsp;</p>
      <p>1. Il Titolare del trattamento &egrave; <span class="bold">STYLEM ITALIA S.R.L.</span> (in seguito, &ldquo;Titolare&rdquo;), con sede legale in Prato (PO) - 59100, via Delle Fonti, 424 – tel. +39 0574870718.&nbsp;</p>
      <p>2. I dati da Lei forniti verranno trattati per le seguenti finalit&agrave;:</p>
      <p>per eseguire gli obblighi derivanti dal contratto stipulato tra Lei e il Titolare (ad esempio, completamento di ordini) e per eseguire i conseguenti adempimenti contabili, fiscali ed amministrativi; quanto alla prima finalit&agrave;, la base giuridica del trattamento risiede nell&rsquo;esecuzione di obblighi derivanti da un contratto; quanto alla seconda, nella necessit&agrave; di adempimento a un obbligo legale cui &egrave; soggetto il Titolare.</p>
      <p>3. &nbsp;Il conferimento dei dati &egrave; obbligatorio ai fini della gestione contrattuale e fiscale del rapporto, pena la mancata esecuzione del contratto o la mancata prosecuzione del rapporto stesso.&nbsp;</p>
      <p>4. I destinatari del trattamento sono i responsabili del trattamento (tra cui soggetti terzi al titolare) e le persone interne all&rsquo;organizzazione del Titolare, dietro autorizzazione dello stesso. I dati personali non saranno oggetto di diffusione, ma potranno essere comunicati a terzi, anche nominati Responsabili del trattamento (il cui elenco &egrave; a disposizione contattando il Titolare all&rsquo;indirizzo in apertura) esclusivamente per esigenze tecniche e operative strettamente collegate alle finalit&agrave; sopra enunciate. In particolare, a societ&agrave; o professionisti per l&rsquo;evasione di incombenze contabili e/o fiscali; societ&agrave; informatiche per la gestione dei sistemi informatici; societ&agrave; fornitrici di software.</p>
      <p>5. I dati sono trattati e conservati presso la sede legale del Titolare del Titolare. Tuttavia, si fa presente che il Titolare si avvale del servizio Gmail, offerto dalla societ&agrave; Google inc., con sede legale negli Stati Uniti. Tale societ&agrave; offre una serie di meccanismi per il trasferimento di dati a livello internazionale e dispongono della certificazione ai sensi dei framework EU - U.S., costituente un sistema legale per il trasferimento dei dati personali dal SEE agli Stati Uniti, all&apos;interno del quale le organizzazioni certificate garantiscono di fornire un livello di protezione in linea con la normativa europea per la protezione dei dati.</p>
      <p>6. I dati personali saranno conservati per il tempo necessario ad adempiere alle finalit&agrave; di cui sopra, e in ogni caso, in conformit&agrave; alla normativa vigente.&nbsp;</p>
      <p>7. &nbsp;Lei potr&agrave;, in qualsiasi momento: accedere ai Suoi dati personali; ottenere la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano; opporsi al trattamento; esercitare il diritto alla portabilit&agrave; dei Suoi dati; revocare il consenso; proporre reclamo all&rsquo;Autorit&agrave; di controllo (Garante Privacy). L&rsquo;esercizio dei Suoi diritti potr&agrave; avvenire attraverso richiesta al numero di telefono riportato in apertura.<span style="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
      <p><br></p>
      <p>Il Titolare del trattamento</p>
      <p><span class="bold">STYLEM ITALIA S.R.L.</span></p>
      <p><br></p>
    </div>
    <div v-if="type === 'privacy-cookie-policy-en'">
      <h4>Privacy Policy</h4>
      <p><br></p>
      <p>This information is prepared on the basis of the legislation on the protection of personal data, including articles 13 and 14 of the Regulation (EU) 2016/679 (hereinafter, GDPR) and of the legislative decree n. 196/2003 (Privacy Code) as amended by Legislative Decree n. 101/2018.&nbsp;</p>
      <p><br></p>
      <h5>Data Controller</h5>
      <p>Stylem Italia s.rl. (hereinafter, &quot;Controller&quot;), with registered office in Prato (PO) - 55100, via Campostino di Mezzana, 7/9 - tel. +39 0574870718 - email CONTACTUS@STYLEMITALIA.IT.</p>
      <p><br></p>
      <h5>Types of Data collected and purpose of processing&nbsp;</h5>
      <p><br></p>
      <p>a) <span class="bold">Contacts.</span> It is possible to contact the Controller to request information and for any request relating to the products and services offered. The data collected are those contained in the body of the message sent to the Controller&rsquo;s email address.</p>
      <p>The purpose of the processing of your data is to fulfill your requests, and the legal basis of the processing lies in the execution of pre-contractual and contractual measures (Article 6, letter b, GDPR).</p>
      <p>b) <span class="bold">Content on external platforms.</span> These services allow you to view content hosted on external platforms directly from the pages of this Site and interact with them. In the event that a service of this type is installed, it is possible that, even if the Users do not use the service, it collects traffic data relating to the pages in which it is installed.</p>
      <p>The widgets that are installed on this site are:</p>
      <p><span class="bold">-Google Maps:</span> it&rsquo;s a map visualization service managed by Google Inc. that allows this Site to integrate such contents within its pages. The personal data collected by the Controller through the platform are cookies and usage data; please refer to the privacy policy of Google (https://policies.google.com/privacy?hl=it), pointing out that your data in this case may be processed by the same, even in non-EU countries. In any case, Google continues to offer a number of mechanisms for the transfer of data internationally;</p>
      <p><span class="bold">-E-mail</span>: it is a service that allows the User to directly send an e-mail message to the Controller through the use of the platform connected to the Controller&apos;s email address. Please refer to the privacy policy of the user&apos;s email manager;</p>
      <p><span class="bold">-Instagram.</span> The Instagram social link is an interaction service with the Instagram social network, provided by Instagram Inc., a company acquired by Facebook Inc. For more information on its Privacy Policy, please refer to the https://help.instagram.com/519522125107875 page.</p>
      <p><br></p>
      <p><br></p>
      <h5>Method and place of processing of the collected Data</h5>
      <p><br></p>
      <p>The processing is carried out using IT and/or telematic tools, with organizational methods and with logic strictly related to the purposes indicated and, in particular, through the adoption of adequate security measures pursuant to Regulation (EU) 2016/679. In addition to the Controller, in some cases, other subjects involved in the organization (personnel authorized by the Controller) or external subjects (such as third party technical service providers, hosting providers, website managers) may have access to the data, also appointed, if necessary, Data Processors by the Owner.</p>
      <p><br></p>
      <h5>Legal basis of the processing</h5>
      <p><br></p>
      <p>The Controller processes Personal Data relating to the User, for the purposes referred to:</p>
      <p>-Point 1, letter a) and letter b): the processing of your data is necessary to fulfill your requests (in particular, for the execution of pre-contractual measures such as request for information on the services of the Controller).</p>
      <p><br></p>
      <h5>Place and scope of communication of your Data</h5>
      <p><br></p>
      <p>The data are processed and stored at the Controller&apos;s registered office by personnel authorized by the Controller or by persons in charge of occasional maintenance operations.</p>
      <p>Personal data are stored on servers located within the European Union, except as reported in point 1, lett. b) of this privacy policy to which reference is made.</p>
      <p>Your data may be disclosed to third parties belonging to the following categories: subjects that provide services for the management of the website used by the Controller and the communication networks (including e-mail); competent authorities for the fulfillment of legal obligations and/or provisions of public authorities, upon request.</p>
      <p>Your data will not be disseminated.</p>
      <p>The subjects belonging to the aforementioned categories perform the function of data processing managers, or operate in total autonomy from the Controller.</p>
      <p>The User can request information on the constantly updated list of data processors from the Controller by contacting him at the details indicated at the beginning.</p>
      <p><br></p>
      <h5>Nature of the provision of Data and consequences of refusal to provide Personal Data&nbsp;</h5>
      <p><br></p>
      <p>The provision of Data for the purposes referred to in point 1, lett. a) is functional to use the services requested, therefore any refusal to provide them makes it impossible for the Controller to perform these services.</p>
      <p><br></p>
      <h5>Retention period</h5>
      <p><br></p>
      <p>The Data are processed and stored for the time required by the purposes for which they were collected.</p>
      <p>Therefore:</p>
      <p>Personal Data collected for purposes related to the execution of a contract or the execution of pre-contractual measures between the Controller and the User will be retained until the execution of this contract is completed and, in any case, in compliance with current legislation.</p>
      <p>The Controller may be obliged to keep Personal Data for a longer period in compliance with a legal obligation or by order of an authority.</p>
      <p>At the end of the retention period, the personal data will be deleted. Therefore, at the end of this term the right of access, cancellation, rectification and the right to data portability can no longer be exercised.</p>
      <p><br></p>
      <h5>Rights of the User</h5>
      <p><br></p>
      <p>Users can exercise certain rights with reference to the data processed by the Controller.</p>
      <p>In particular, pursuant to the GDPR and current legislation, the User has the right to:</p>
      <p class="bold">-Withdraw consent at any time;</p>
      <p class="bold">-Oppose the processing of their data;</p>
      <p class="bold">-Access their data;</p>
      <p class="bold">-Verify and request rectification;</p>
      <p class="bold">-Obtain the limitation of the processing;</p>
      <p class="bold">-Obtain the cancellation or removal of their personal data;</p>
      <p class="bold">-Receive their data or have them transferred to another owner;</p>
      <p class="bold">-Propose a complaint to the Supervisory Authority (Privacy Guarantor).</p>
      <p><br></p>
      <h5>How to exercise your rights</h5>
      <p><br></p>
      <p>To exercise their rights, the User can direct a request to the contact details of the Controller indicated in this document.</p>
      <p><br></p>
      <h5>System log and maintenance</h5>
      <p><br></p>
      <p>For needs related to operation and maintenance, this application may collect system logs, which are files that record the interactions and which may also contain personal data, such as the user&apos;s IP address.</p>
      <p><br></p>
      <h5>Information not contained in this policy</h5>
      <p><br></p>
      <p>Further information in relation to the processing of Personal Data may be requested at any time from the Controller using the contact details indicated above.</p>
      <p><br></p>
      <p><br></p>
      <h4>Cookie Policy</h4>
      <p><br></p>
      <p>Cookies are used on our website www.stylemitalia.it to provide users with a better browsing service and experience.</p>
      <p><br></p>
      <h5>What are cookies?</h5>
      <p><br></p>
      <p>Cookies are small text files sent from the Site to the interested party&apos;s terminal (usually to the browser), where they are stored before being re-transmitted to the Site at the next visit by the same user. A cookie cannot retrieve any other data from the user&apos;s hard drive, nor transmit computer viruses or acquire email addresses. Each cookie is unique to the user&apos;s web browser.</p>
      <p><br></p>
      <h5>Technical session cookies</h5>
      <p><br></p>
      <p>The cookies used on the website www.stylemitalia.it have the purpose of carrying out computer authentication or monitoring sessions and storing specific technical information regarding the users who access the servers, and the site maintenance service manager.</p>
      <p>For maximum transparency, a series of technical cookies and specific operational cases are listed below:</p>
      <p>-The cookies implanted in the user&apos;s / contractor&apos;s terminal directly (which will not be used for other purposes) such as session cookies, personalization cookies (for example, for the choice of the navigation language), remain active only for the duration of the session;</p>
      <p>-The cookies used to statistically analyze accesses / visits to the site (so-called &quot;analytics&quot; cookies) only pursue statistical purposes (and not profiling or marketing) and collect information in aggregate form without the possibility of tracing the identification of the individual user. We specify below how to disable the installed cookies.</p>
      <p><br></p>
      <h5>How to change your cookie settings</h5>
      <p><br></p>
      <p>Most browsers allow you to delete cookies from your computer hard drive, block the acceptance of cookies or receive a warning before a cookie is stored.</p>
      <p>Therefore, for the removal of cookies we invite the user to follow the instructions on the dedicated pages of the various browsers:</p>
      <p>-Chrome: https://support.google.com/chrome/answer/95647?hl=it</p>
      <p>- Firefox: https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</p>
      <p>- Internet Explorer: http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9</p>
      <p>- Safari: http://support.apple.com/kb/HT1677?viewlocale=it_IT</p>
      <p><br></p>
      <h5>&quot;Third party&quot; cookies</h5>
      <p><br></p>
      <p>Third party cookies are pieces of code set by a website other than the one you are currently visiting.</p>
      <p>This involves the transmission of cookies from third parties. The management of the information collected by &quot;third parties&quot; is governed by the relevant information to which reference should be made. To ensure greater transparency and convenience, the information on the type of cookies used through the services of third-party suppliers, purposes, retention period, third parties who still retain the information and which they access it:</p>
      <p><br></p>
      <h5>Aggregate statistics cookies</h5>
      <p><br></p>
      <p>Google Analytics with anonymized IP (Google Inc.) Google Analytics is a web analysis service provided by Google Inc. (&quot;Google&quot;). Google uses the Personal Data collected for the purpose of tracking and examining the use of this Application, compiling reports and sharing them with other services developed by Google.</p>
      <p>Google may use the Personal Data to contextualize and personalize the advertisements of its own advertising network.</p>
      <p>This Google Analytics integration makes your IP address anonymous. Anonymization works by shortening the IP address of the Users within the borders of the member states of the European Union or in other countries adhering to the agreement on the European Economic Area. Only in exceptional cases, the IP address will be sent to Google&apos;s servers and shortened within the United States.</p>
      <p>Personal Data collected: Cookies and Usage Data.</p>
      <p>Place of processing: United States - Privacy Policy &ndash; Opt Out.</p>
      <p><br></p>
      <h5>Google Maps widget (Google Inc.)</h5>
      <p><br></p>
      <p>Google Maps is a map visualization service managed by Google Inc. that allows this Application to integrate such contents within its pages.</p>
      <p>- Personal data collected: Cookies and Usage data.</p>
      <p>- Place of processing: USA - Privacy Policy</p>
      <p><br></p>
      <h5>Instagram button (Instagram)</h5>
      <p><br></p>
      <p>The Instagram button is an interaction service with the Instagram social network, provided by Instagram Inc.</p>
      <p>- Personal data collected: Cookies and Usage data.</p>
      <p>- Place of processing: USA - Privacy Policy</p>
      <p><br></p>
      <h5>Legal references</h5>
      <p><br></p>
      <p>This information has been prepared in fulfillment of the obligations established by Regulation (EU) 2016/679 and the current legislation on the protection of personal data, as well as the provisions of the legislation on Cookies.</p>
      <p>For more information, you can read the Privacy Policy.</p>
      <p><br></p>
    </div>
    <div v-if="type === 'privacy-cookie-policy-it'">
      <h4>Privacy Policy</h4>
      <p><br></p>
      <p>La presente informativa &egrave; redatta sulla base della normativa in materia di protezione dei dati personali, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679 (in seguito, GDPR) e del d.lgs. n. 196/2003 (Codice della Privacy) cos&igrave; come modificato dal d.lgs. n. 101/2018.</p>
      <p><br></p>
      <h5>Titolare del Trattamento dei Dati</h5>
      <p>Stylem Italia s.rl. (in seguito, &ldquo;Titolare&rdquo;), con sede legale in Prato (PO) - 55100, via Campostino di Mezzana, 7/9 &ndash; tel. +39 0574870718 &ndash; email CONTACTUS@STYLEMITALIA.IT.</p>
      <p><br></p>
      <h5>Tipologie di Dati raccolti e Finalit&agrave; del trattamento</h5>
      <p><br></p>
      <p><span class="bold">a) Contatti.</span> E&rsquo; possibile contattare il Titolare per richiedere informazioni e per ogni richiesta relativa ai prodotti e servizi offerti. I dati raccolti sono quelli contenuti nel corpo del messaggio inviato all&rsquo;indirizzo email del Titolare.&nbsp;</p>
      <p>La finalit&agrave; del trattamento dei Suoi dati &egrave; evadere le Sue richieste, e la base giuridica del trattamento risiede nell&rsquo;esecuzione di misure precontrattuali e contrattuali (Art. 6, lett. b, GDPR).</p>
      <p><span class="bold">b) Contenuti su piattaforme esterne.</span> Questi servizi permettono di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questo Sito e di interagire con essi. Nel caso in cui sia installato un servizio di questo tipo, &egrave; possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui &egrave; installato.&nbsp;</p>
      <p>I widget che sono installati sul presente sito sono:</p>
      <p><span class="bold">-Google Maps:</span> &egrave; un servizio di visualizzazione di mappe gestito da Google Inc. che permette a questo Sito di integrare tali contenuti all&rsquo;interno delle proprie pagine. I dati personali raccolti dal Titolare tramite la piattaforma sono cookie e dati di utilizzo; si rinvia alla privacy policy di Google (https://policies.google.com/privacy?hl=it) , facendo presente che i Suoi dati in tal caso potranno essere trattati dallo stesso, anche in paesi extra-UE. In ogni caso, Google continua a offrire una serie di meccanismi per il trasferimento di dati a livello internazionale;</p>
      <p><span class="bold">-Posta elettronica:</span> &egrave; un servizio che permette all&rsquo;Utente di inviare direttamente un messaggio di posta elettronica al Titolare tramite l&rsquo;utilizzo della piattaforma collegata all&rsquo;indirizzo email del Titolare. Si rinvia alla privacy policy del gestore di posta elettronica dell&rsquo;utente;</p>
      <p><span class="bold">-Instagram.</span> Il link sociale di Instagram &egrave; un servizio di interazione con il social network Instagram, fornito da Instagram, Inc, societ&agrave; acquisita da Facebook Inc. Per ulteriori informazioni sulla sua Privacy Policy, si rinvia alla pagina https://help.instagram.com/519522125107875.</p>
      <p><br></p>
      <p><br></p>
      <h5>Modalit&agrave; e luogo del trattamento dei Dati raccolti</h5>
      <p><br></p>
      <p>Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalit&agrave; organizzative e con logiche strettamente correlate alle finalit&agrave; indicate e, in particolare, mediante l&rsquo;adozione delle misure di sicurezza adeguate di cui al Regolamento UE 679/2016. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai dati altri soggetti coinvolti nell&rsquo;organizzazione (personale autorizzato dal Titolare) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, hosting provider, gestori del Sito Internet) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare.</p>
      <p><br></p>
      <h5>Base giuridica del trattamento</h5>
      <p><br></p>
      <p>Il Titolare tratta dati Personali relativi all&rsquo;Utente, per le finalit&agrave; di cui:</p>
      <p>-punto 1, lett a) e lett b): il trattamento dei Suoi dati &egrave; necessario per evadere le Sue richieste (in particolare, per l&rsquo;esecuzione di misure precontrattuali quali richiesta di informazioni sui servizi del Titolare).</p>
      <p><br></p>
      <h5>Luogo e ambito di comunicazione dei Suoi dati</h5>
      <p><br></p>
      <p>I dati sono trattati e conservati presso la sede legale del Titolare dal personale autorizzato dal Titolare stesso oppure da eventuali incaricati di occasionali operazioni di manutenzione.</p>
      <p>I dati personali sono conservati su server ubicati all&rsquo;interno dell&rsquo;Unione Europea, eccetto quanto riportato al punto 1, lett. b) della presente privacy policy al quale si rinvia.</p>
      <p>I Suoi dati potranno essere comunicati a terzi appartenenti alle seguenti categorie: soggetti che forniscono servizi per la gestione del sito Web usato dal Titolare e dalle reti di comunicazione (ivi compresa la posta elettronica); autorit&agrave; competenti per l&rsquo;adempimento di obblighi di legge e/o disposizioni delle pubbliche autorit&agrave;, su richiesta.&nbsp;</p>
      <p>I Suoi dati non saranno oggetto di diffusione.</p>
      <p>I soggetti appartenenti alle suddette categorie svolgono la funzione di Responsabili del trattamento dei dati, oppure operano in totale autonomia dal Titolare.&nbsp;</p>
      <p>L&rsquo;Utente pu&ograve; chiedere informazioni sull&rsquo;elenco, costantemente aggiornato, dei responsabili del trattamento al Titolare contattandolo agli estremi riportati in apertura.</p>
      <p><br></p>
      <h5>Natura del conferimento dei dati e conseguenze del rifiuto di rispondere</h5>
      <p><br></p>
      <p>Il conferimento dei dati per le finalit&agrave; di cui al punto 1, lett. a) &egrave; funzionale per usufruire dei servizi richiesti, pertanto l&rsquo;eventuale rifiuto di conferirli comporta l&rsquo;impossibilit&agrave; per il Titolare di eseguire tali servizi.&nbsp;</p>
      <p><br></p>
      <h5>Periodo di conservazione</h5>
      <p><br></p>
      <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalit&agrave; per le quali sono stati raccolti.</p>
      <p>Pertanto:</p>
      <p>I Dati Personali raccolti per scopi collegati all&rsquo;esecuzione di un contratto o all&rsquo;esecuzione di misure precontrattuali tra il Titolare e l&rsquo;Utente saranno trattenuti sino a quando sia completata l&rsquo;esecuzione di tale contratto e, comunque, in conformit&agrave; alla normativa vigente.</p>
      <p>Il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo pi&ugrave; lungo in ottemperanza ad un obbligo di legge o per ordine di un&rsquo;autorit&agrave;.</p>
      <p>Al termine del periodo di conservazione, i dati personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilit&agrave; dei Dati non potranno pi&ugrave; essere esercitati.</p>
      <p><br></p>
      <h5>Diritti dell&rsquo;Utente</h5>
      <p><br></p>
      <p>Gli Utenti possono esercitare determinati diritti con riferimento ai dati trattati dal Titolare.</p>
      <p>In particolare, ai sensi del GDPR e della normativa vigente, l&rsquo;Utente ha il diritto di:</p>
      <p class="bold">-revocare il consenso in ogni momento;&nbsp;</p>
      <p class="bold">-opporsi al trattamento dei propri dati;</p>
      <p class="bold">-accedere ai propri dati;</p>
      <p class="bold">-verificare e chiedere la rettificazione;</p>
      <p class="bold">-ottenere la limitazione del trattamento;</p>
      <p class="bold">-ottenere la cancellazione o rimozione dei propri dati personali;&nbsp;</p>
      <p class="bold">-ricevere i propri dati o farli trasferire ad altro titolare;</p>
      <p class="bold">-proporre reclamo all&rsquo;Autorit&agrave; di controllo (Garante Privacy).</p>
      <p><br></p>
      <h5>Come esercitare i diritti</h5>
      <p><br></p>
      <p>Per esercitare i propri diritti, l&rsquo;Utente pu&ograve; indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento.&nbsp;</p>
      <p><br></p>
      <h5>Log di sistema e manutenzione</h5>
      <p><br></p>
      <p>Per necessit&agrave; legate al funzionamento ed alla manutenzione, questa applicazione potrebbe raccogliere Log di sistema, ossia file che registrano le interazioni e che possono contenere anche dati personali, quali l&rsquo;indirizzo IP dell&rsquo;Utente.&nbsp;</p>
      <p><br></p>
      <h5>Informazioni non contenute in questa policy</h5>
      <p><br></p>
      <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto sopra riportati.</p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <h4>Cookie Policy</h4>
      <p><br></p>
      <p>I cookie vengono utilizzati sul nostro sito Web www.stylemitalia.it per consentire agli utenti un servizio e un&apos;esperienza di navigazione migliore.</p>
      <p><br></p>
      <h5>Cosa sono i cookie?</h5>
      <p><br></p>
      <p>I cookies sono piccoli file di testo inviati dal Sito al terminale dell&apos;interessato (solitamente al browser), dove vengono memorizzati per essere poi ritrasmessi al Sito alla successiva visita del medesimo utente. Un cookie non pu&ograve; richiamare nessun altro dato dal disco fisso dell&apos;utente, n&eacute; trasmettere virus informatico o acquisire indirizzi email. Ogni cookie &egrave; unico per il web browser dell&apos;utente.</p>
      <p><br></p>
      <h5>Cookies tecnici di sessione</h5>
      <p><br></p>
      <p>I cookies utilizzati sul sito www.stylemitalia.it hanno la finalit&agrave; di eseguire autenticazioni informatiche o il monitoraggio di sessioni e la memorizzazione di informazioni tecniche specifiche riguardanti gli utenti che accedono ai server, ed il gestore del servizio di manutenzione del sito.</p>
      <p>Per la massima trasparenza, si elencano di seguito una serie di cookies tecnici e di casi di operativit&agrave; specifica:</p>
      <p>-i cookie impiantati nel terminale dell&apos;utente/contraente direttamente (che non saranno utilizzati per scopi ulteriori) come ad esempio cookies di sessione, cookie di personalizzazione (ad esempio, per la scelta della lingua di navigazione), rimangono attivi solamente per la durata della sessione;</p>
      <p>-i cookie utilizzati per analizzare statisticamente gli accessi/le visite al sito (cookies cosiddetti &quot;analytics&quot;) perseguono esclusivamente scopi statistici (e non anche di profilazione o di marketing) e raccolgono informazioni in forma aggregata senza possibilit&agrave; di risalire alla identificazione del singolo utente. Specifichiamo di seguito le modalit&agrave; per disattivare i cookies installati.</p>
      <p><br></p>
      <h5>Come modificare le impostazioni sui cookie</h5>
      <p><br></p>
      <p>La maggior parte dei browser permette di cancellare i cookie dal disco fisso del computer, di bloccare l&apos;accettazione dei cookie o di ricevere un avviso prima che un cookie venga memorizzato.</p>
      <p>Pertanto, per la rimozione dei cookies invitiamo l&apos;utente a seguire le istruzioni presenti sulle pagine dedicate dei vari browser:</p>
      <p>-Chrome: https://support.google.com/chrome/answer/95647?hl=it&nbsp;</p>
      <p>- Firefox: https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</p>
      <p>- Internet Explorer: http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9</p>
      <p>- Safari: http://support.apple.com/kb/HT1677?viewlocale=it_IT</p>
      <p><br></p>
      <h5>Cookies &quot;di terze parti&quot;</h5>
      <p><br></p>
      <p>I cookie di terze parti sono parti di codice impostati da un sito web diverso da quello che si sta attualmente visitando.</p>
      <p>Ci&ograve; comporta la trasmissione di cookie da terzi soggetti. La gestione delle informazioni raccolte da &quot;terze parti&quot; &egrave; disciplinata dalle relative informative cui si prega di fare riferimento. Per garantire una maggiore trasparenza e comodit&agrave;, si riportano qui di seguito per ciascun cookie operativo sul nostro Sito, le informazioni su tipologia di cookies utilizzati mediante i servizi di terzi fornitori, scopi, periodo di conservazione, terze parti che comunque conservano le informazioni e che vi accedono:</p>
      <p>Cookie di statistica aggregata:</p>
      <p><br></p>
      <h5>Google Analytics con IP anonimizzato (Google Inc.)</h5>
      <p><br></p>
      <p>Google Analytics &egrave; un servizio di analisi web fornito da Google Inc. (&ldquo;Google&rdquo;). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l&rsquo;utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.</p>
      <p>Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
      <p>Questa integrazione di Google Analytics rende anonimo il tuo indirizzo IP. L&apos;anonimizzazione funziona abbreviando entro i confini degli stati membri dell&apos;Unione Europea o in altri Paesi aderenti all&apos;accordo sullo Spazio Economico Europeo l&apos;indirizzo IP degli Utenti. Solo in casi eccezionali, l&apos;indirizzo IP sar&agrave; inviato ai server di Google ed abbreviato all&apos;interno degli Stati Uniti.</p>
      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
      <p>Luogo del trattamento: Stati Uniti &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p><br></p>
      <h5>Widget Google Maps (Google Inc.)</h5>
      <p><br></p>
      <p>Google Maps &egrave; un servizio di visualizzazione di mappe gestito da Google Inc. che permette a questa Applicazione di integrare tali contenuti all&rsquo;interno delle proprie pagine.</p>
      <p>&ndash; Dati personali raccolti: Cookie e Dati di utilizzo.</p>
      <p>&ndash; Luogo del trattamento : USA &ndash; Privacy Policy</p>
      <p><br></p>
      <h5>Pulsante Instagram (Instagram)</h5>
      <p><br></p>
      <p>Il pulsante Instagram &egrave; un servizio di interazione con il social network Instagram, fornito da Instagram Inc.</p>
      <p>&ndash; Dati personali raccolti: Cookie e Dati di utilizzo.</p>
      <p>&ndash; Luogo del trattamento : USA &ndash; Privacy Policy</p>
      <p><br></p>
      <h5>Riferimenti legali&nbsp;</h5>
      <p><br></p>
      <p>La presente informativa &egrave; redatta in adempimento degli obblighi previsti dal Regolamento UE 2016/679 e della normativa vigente in materia di protezione dei dati personali, nonch&eacute; di quanto previsto dalla normativa in materia di Cookie.&nbsp;</p>
      <p>Per ulteriori informazioni, pu&ograve; prendere visione della Privacy Policy. &nbsp;</p>
      <p><br></p>
      <p><br></p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    type () {
      const lang = this.$route.params.language === 'it' ? 'it' : 'en'
      return this.$route.params.docname ? this.$route.params.docname + '-' + lang : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.document {
  width: 80%;
  margin: auto;

  @include breakpoint('xs-only') {
    width: 100%;
    margin-top: 100px !important;
  }
}
</style>
