<template>
  <div class="contact py-0">
    <div class="bg" :style="{ backgroundImage: 'url(' + external5 + ')' }"></div>
    <v-container fluid class="mt-12 pa-9 pa-md-16" fill-height>
      <v-row align="center" justify="center" class="flex-row-reverse flex-md-row flex-column-reverse">
        <v-col class=" px-6">
          <iframe width="100%" height="350" jsname="L5Fo6c" class="YMEQtf" sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads" frameborder="0" aria-label="Map, Stylem Italia S.R.L." src="https://maps-api-ssl.google.com/maps?hl=en&amp;ll=43.853583,11.100259&amp;output=embed&amp;q=Via+Campostino+di+Mezzana,+7/9,+59100+Prato+PO,+%E3%82%A4%E3%82%BF%E3%83%AA%E3%82%A2+(Stylem+Italia+S.R.L.)&amp;z=16" allowfullscreen=""></iframe>
        </v-col>
        <v-col cols="12" md="6" class="uppercase px-6" >
          <h3 class="uppercase bold mb-3 company">STYLEM ITALIA S.R.L</h3>
          <div class="address">
            <h6>via Campostino di Mezzana 7/9</h6>
            <h6>59100 Prato</h6>
            <h6>Italia</h6>
          </div>
          <div class="my-8 contact">
            <h6>Tel: <span class="bold">+39 0574 870718</span></h6>
            <h6>Fax: <span class="bold">+39 0574 870720</span></h6>
            <h6>Mail: <a class="bold" href="mailto:contactus@stylemitalia.it">contactus@stylemitalia.it</a></h6>
            <h6>PEC: <a class="bold" href="mailto:stylemitalia@legalmail.it">stylemitalia@legalmail.it</a></h6>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import external5 from '@/assets/img/external5.jpg'
export default {
  data () {
    return {
      external5
    }
  }
}
</script>

<style lang="scss" scoped>
.company {
  letter-spacing: 1px;
}

.contact {
  height: 100%;
}

.bg {
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 0;
  opacity: 0.2;
}

.container {
  position: relative;
}
</style>
