<template>
  <div class="contact py-0">
    <div class="bg" :style="{ backgroundImage: 'url(' + external5 + ')' }"></div>
    <v-container fluid class="mt-12 pa-9 pa-md-16" fill-height>
      <v-row class="mb-12 px-3 px-md-6" justify="center" align="center">
        <v-col cols="12" md="6" >
          <v-img class="" :src="internal3"></v-img>
        </v-col>
        <v-col cols="12" md="6" >
          <h2 class="uppercase"><span class="light">Our</span> Certification</h2>
        </v-col>
      </v-row>
      <v-row align="center text-align-left">
        <v-col class="px-9 px-md-16" cols="12" justify="center">
          <div class="content">
            <a href="https://stylemitalia.it/pdf/certificate-grs-stylemitalia.pdf" target="_blank">
              <img class="cert-logo mr-6" :src="grs"><span>GRS - Global Recycled Standard</span>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import internal3 from '@/assets/img/internal3.jpg'
import grs from '@/assets/img/grs.png'
export default {
  data () {
    return {
      internal3,
      grs
    }
  }
}
</script>

<style lang="scss" scoped>
.company {
  letter-spacing: 1px;
}

.contact {
  height: 100%;
}

.bg {
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 0;
  opacity: 0.2;
}

.container {
  position: relative;
}

a h5 {
  text-decoration: underline;
  display: inline-block;
}

.cert-logo {
  height: 140px;
  display: inline-block;

  @include breakpoint('sm-and-down') {
    height: 80px;
  }
}

.content {
  text-align: center;
}

.content a {
  margin: auto;
  display: inline-flex;
  align-items: center;
}
</style>
