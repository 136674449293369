<template>
  <v-container class="home-page mt-16 pt-16 px-9 px-md-0">
    <v-row align="center" class="mb-16 rollover collection" justify="center">
      <v-col cols="12" md="7">
        <router-link :to="'Collection'">
          <v-img :src="letinte36" class="img-box"></v-img>
        </router-link>
      </v-col>
      <v-col class="mt-md-0 relative" cols="12" md="5" align="center" justify="center">
        <router-link :to="'Collection'">
          <!--<img :src="letinteLogo" class="logo">-->
          <h2 class="bold" v-if="$route.params.language === 'it'">COLLEZIONE</h2>
          <h2 class="bold" v-else>COLLECTION</h2>
          <div class="medium-line"></div>
        </router-link>
      </v-col>

    </v-row>
    <v-row class="my-12 flex-md-row flex-column-reverse" align="center" justify="center">
      <v-col cols="12" md="5" align="center" justify="right" class="mt-md-0 relative text-align-right">
        <router-link :to="'Identity'">
          <h2 v-if="$route.params.language === 'it'">IDENTITÀ</h2>
          <h2 v-else>IDENTITY</h2>

          <h5 v-if="$route.params.language === 'it'" class="mt-6">Quello che caratterizza <span class="font-script">“Le Tinte”</span> è l’aspetto dei materiali</h5>
          <h5 v-else class="mt-6">What characterizes <span class="font-script">“Le Tinte”</span> is the look of their materials.</h5>

          <div class="medium-line inline-block"></div>

          <p v-if="$route.params.language === 'it'" class="uppercase mt-6">Per saperne di più ></p>
          <p v-else class="mt-6">READ MORE ></p>
        </router-link>
      </v-col>
      <v-col cols="12" md="7" class="pl-md-16">
        <router-link :to="'Identity'">
          <div class="content-box rollover">
            <v-img :src="letinte69" class="img-box"></v-img>
          </div>
        </router-link>
      </v-col>
    </v-row>
    <v-row class="my-12" align="center" justify="center">
      <!--<v-col cols="12" md="7">
        <router-link :to="'History'">
          <div class="content-box rollover">
            <v-img :src="internal3" class="img-box"></v-img>
            <h4 v-if="$route.params.language === 'it'">STORIA</h4>
            <h4 v-else>HISTORY</h4>
          </div>
        </router-link>
      </v-col>-->
      <v-col cols="12" md="7" >
        <router-link :to="'Company'">
          <div class="content-box rollover">
            <v-img :src="company2" class="img-box"></v-img>
          </div>
        </router-link>
      </v-col>
      <v-col cols="12" md="5" align="center" justify="left">
        <router-link :to="'Company'">
          <div class="rollover">
            <h2 v-if="$route.params.language === 'it'">AZIENDA</h2>
            <h2 v-else>COMPANY</h2>
            <p class="mt-3" v-if="$route.params.language === 'it'">La storia di Prato, centro dell’industria tessile toscana e Italiana, inizia nel lontano medioevo con la produzione del panno. </p>
            <p class="mt-3" v-else >THE HISTORY OF PRATO IN TUSCANY IN ITALY, ONE OF THE MAIN CENTERS OF THE TEXTILE INDUSTRY ALL OVER THE WORLD, IS LONG AND DATES BACK TO MEDIEVAL EUROPE.</p>
            <p v-if="$route.params.language === 'it'" class="uppercase mt-6">Per saperne di più ></p>
            <p v-else class="mt-6">READ MORE ></p>
          </div>
        </router-link>
      </v-col>
    </v-row>

    <v-row class="my-12 flex-md-row flex-column-reverse" align="center" justify="center">
      <v-col cols="12" md="5" class="pr-md-16 align-md-end">
        <h4 class="mb-6">STYLEM ITALIA S.R.L.</h4>
        <p class="my-0">VIA CAMPOSTINO DI MEZZANA 7/9</p>
        <p class="my-0">59100 PRATO (PO), ITALIA</p>
        <p class="my-0">C.F. e P.IVA 02266840970</p>
        <p class="mt-6" ><a href="mailto:contactus@stylemitalia.it">contactus@stylemitalia.it</a></p>

        <!--<a class="logo-group" href="https://en.stylem.co.jp/" target="_blank">
          <img :src="stylemLogo" ><span>GROUP</span>
        </a>-->
      </v-col>
      <v-col cols="12" md="7">
        <router-link :to="'Contact'">
          <div class="content-box rollover">
            <v-img :src="external5" class="img-box"></v-img>
            <h4 v-if="$route.params.language === 'it'">CONTATTI</h4>
            <h4 v-else>CONTACT</h4>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import grs from '@/assets/img/grs.png'
import letinte13 from '@/assets/img/Letinte13.jpg'
import company2 from '@/assets/img/Company_2.jpg'
import letinte36 from '@/assets/img/Letinte36.jpg'
import letinte63 from '@/assets/img/Letinte63.jpg'
import letinte69 from '@/assets/img/Letinte69.jpg'
import letinte70 from '@/assets/img/Letinte70.jpg'
import external5 from '@/assets/img/external5.jpg'
import internal5 from '@/assets/img/internal5.jpg'
import internal3 from '@/assets/img/internal3.jpg'
import letinteLogo from '@/assets/img/le-tinte.png'
import stylemLogo from '@/assets/img/stylem-logo.png'

import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'

export default {
  name: 'Home',

  components: {
  },

  mixins: [Viewport],

  data () {
    return {
      grs,
      letinte13,
      company2,
      letinte36,
      letinte63,
      letinte70,
      letinte69,
      external5,
      internal5,
      internal3,
      letinteLogo,
      stylemLogo
    }
  },

  computed: {
    complexTranslation: function () {
      // this is an example of a complex translation that implements strong tags / router links / lists etc

      // comment for translator: in homepage
      return this.$gettext('Please feel free to\n\n- ~~stress [Daniele](mailto:daniele@mono-grid.com)~~\n- [Switch Language](Home "language: \'it\'") to Italian')
    }
  },

  watch: {
    canvasVisible () {
      this.calculateCanvasSize()
    }
  },
  methods: {
  },

  metaInfo: function () {
    return {
      // home page browser title
      title: this.$gettext('Home Page')
    }
  }
}
</script>

<style scoped lang="scss">
.white {
  h4::before {
    display: none;
  }

  .logo-group img {
    filter: invert(1);
  }
}

.img-box {
  width: 100%;
  max-height: 60vh;
}

.img-grs {
  width: 80%;
}

.logo {
  height: 16vh;
}

.collection {
  &:hover {
    h2 {
      letter-spacing: 1px;
    }
  }

  h2 {
    transition: letter-spacing 0.5s;
  }
}

.rollover {
  &:hover {
    ::v-deep .v-image__image {
      transform: scale(1.2);
    }
  }

  ::v-deep .v-image__image {
    transition: transform 0.5s;
  }
}

.content-box {
  overflow: visible;
  background-size: cover;
  background-position: center center;
  height: 60vh;
  position: relative;

  @include breakpoint('xs-only') {
    height: 35vh;
  }

  &:hover {
    .img-box {
      opacity: 1;
    }

    h4 {
      transform: translateX(50px);
      letter-spacing: 4px;
    }
  }

  .img-box {
    overflow: hidden;
    height: 100%;
    opacity: 0.7;
    transition: opacity 0.5s;
  }

  h4 {
    position: absolute;
    bottom: 20px;
    left: -20px;
    font-weight: 600 !important;
    z-index: 1;
    letter-spacing: 8px;
    transition: all 0.5s;
    pointer-events: none;

    &::before {
      pointer-events: none;
      content: '';
      z-index: -1;
      width: 300%;
      height: 400%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: radial-gradient(circle, rgba(2, 0, 0, 0.5) 0%, rgba(46, 46, 46, 0.5) 13%, rgba(43, 43, 43, 0) 30%);
    }
  }
}

.logo-group {
  img {
    height: 18px;
  }

  span {
    margin-left: 10px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
  }
}
</style>
