<template>
  <v-row class="company  mt-16 pt-16" fluid justify="center">
    <v-col class="px-9 px-md-16" cols="12" md="10">
      <div class="content" v-if="$route.params.language === 'en'">
        <h1 class="uppercase text-align-center small-underline">About us</h1>
        <h5 class="uppercase my-12">The history of Prato in Tuscany in Italy, one of the main centers of the textile industry all over the world, is long and dates back to medieval Europe.
        </h5>
        <v-img :src="bgImage" class="px-n16 mt-16"></v-img>
        <p class="mt-6">Since then, this area has prospered with the production of wool materials, and today, it has established a solid position as a production center of a broad range of fashion textiles, not just wool materials. Meanwhile, STYLEM TAKISADA-OSAKA CO., LTD., founded in 1864 as a wholesaler of KIMONO, is a trading company which has been involved in textile and garment businesses for more than 150 years. In 2013, the Japanese company dived into Prato to start creating directly his own textile collections. In our original collections, namely <span class="font-script">“Le Tinte”</span>, the tradition and expertise we have cultivated are fused with the rich history and sensitivities that have accumulated in Prato. Selected few members from Japan and local district are working side by side every day with ingenuity to develop our exclusive collection.</p>
      </div>
      <div class="content" v-if="$route.params.language === 'it'">
        <h2 class="uppercase small-underline text-align-center">Chi siamo</h2>
        <h5 class="uppercase my-12">La storia di Prato, centro dell’industria tessile toscana e Italiana, inizia nel lontano medioevo con la produzione del panno. </h5>
        <v-img :src="bgImage" class="mt-16"></v-img>
        <p class="mt-6">Fin da allora i lanaioli pratesi si sono specializzati nel trattamento della lana perfezionando i cicli di lavorazione per rendere i panni più morbidi e belli. Oggi, Prato è ancora uno dei principali centri di produzione tessile a livello mondiale, e tratta non più soltanto lana cardata, bensi un’ampia tipologia di materiali e tessuti. Ed è proprio a Prato che STYLEM TAKISADA-OSAKA Co., LTD. società fondata nel 1864 come grossista di kimono e da più di 150 anni, società commerciale che si occupa di tessuti ed abbigliamento, nel 2013 ha deciso di iniziare a creare e produrre direttamente la propria collezione di tessuti avviando la STYLEM ITALIA SRL. Nella nostra collezione originale, denominata <span class="font-script">„Le Tinte“</span>, la tradizione e la competenza Giapponese si fondono con la ricca storia e la sensibilità Pratese: pochi e selezionati esperti Giapponesi, insieme ad alcuni specialisti del distretto locale, lavorano fianco a fianco ogni giorno con impegno e creatività per sviluppare la nostra collezione.</p>
      </div>
      <div class="content" v-if="$route.params.language === 'jp'">
        <h5 class="uppercase my-12 small-underline text-align-center">イタリア、トスカーナ州を代表する繊維産業の集積地である<br>プラートの歴史は古く、中世ヨーロッパにまで遡ります。</h5>
        <v-img :src="bgImage" class="mt-16"></v-img>
        <p class="mt-6 mt-md-12">以来、羊毛素材の産地として栄え、現在においては、ウール素材のみに留まらず、幅広いファッションテキスタイルの生産地としての地位を確立しています。１８６４年、呉服卸商として創業し、これまで繊維専門商社として、１５０年以上にわたりテキスタイル、及び製品ビジネスを手掛けてきたスタイレム瀧定大阪株式会社は、２０１３年にここプラートに身を投じ、私たち自身の手で一からテキスタイルコレクションを手掛けることを決定しました。当社がこれまでに培った伝統、経験と、プラート産地に蓄積された長い歴史、感性とを融合させたオリジナルコレクション、<br>その名は「<span class="font-script">Le Tinte</span>」、、、。 少数精鋭の日本人、イタリア人が手を携え、創意工夫を重ねながら日々、開発を行っています。</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import bgImage from '@/assets/img/Letinte70.jpg'

export default {
  data () {
    return {
      bgImage
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
