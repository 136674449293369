<template>
  <div class="identity mt-16 pt-16">
    <v-row class="mb-12 px-9 px-md-16" justify="center">
      <h2 v-if="$route.params.language === 'en'" class="uppercase"><span class="light">Our</span> identity</h2>
      <h2 v-if="$route.params.language === 'it'" class="uppercase "><span class="light">La nostra</span> identità</h2>
      <h2 v-if="$route.params.language === 'jp'"><span class="font-script">“Le&nbsp;Tinte”</span>とは</h2>
    </v-row>
    <v-row align="center text-align-left">
      <v-col class="px-9 px-md-16" cols="12" md="6">
        <div class="content" v-if="$route.params.language === 'en'">
          <h5 class="">The brand name represents our idea to color the lifestyles of people throughout the world.</h5>
  <div class="mt-6 medium-line inline-block"></div>
  <h4 class="mt-6 bold uppercase">What characterizes <span class="lowercase font-script">“Le&nbsp;Tinte”</span> is the look of their materials.</h4>

        </div>
        <div class="content" v-if="$route.params.language === 'it'">
          <h5 class="uppercase">Quello che caratterizza <span class="lowercase font-script">“Le&nbsp;Tinte”</span> è l’aspetto dei materiali: </h5>
          <div class="mt-6 medium-line inline-block"></div>
          <p class="mt-12">Creiamo i collezioni di tessuti con look e mani nuovi e attuali. Grazie alla combinazione di un’ampia gamma di tipologie di filati, uniti in tessuti che valorizziamo con processi di finissaggio appositamente studiati per enfatizzare la ricercatezza della stoffa.</p>
        </div>
        <div class="content" v-if="$route.params.language === 'jp'">
          <p class="">世界中の全ての人達のライフスタイルを華やかに彩りたい、<br>その思いがこのブランド名に込められています。</p>
        <div class="mt-6 medium-line inline-block"></div>
          <h4><span class="lowercase font-script">Le Tinte</span> の特長は、</h4>
          <h4 class="text-align-right">その素材の表情にあります。</h4>
        </div>
      </v-col>
      <v-col class="pl-md-12" cols="12" md="6">
        <v-img :src="bgImage"></v-img>
      </v-col>
    </v-row>
    <v-row class="identity mt-12 flex-row-reverse flex-md-row flex-column-reverse" fluid>
      <v-col class="pl-md-12" cols="12" md="6">
        <v-img :src="bgImage2" class=""></v-img>
      </v-col>
      <v-col class="px-9 px-md-16" cols="12" md="6">
        <div justify="left" class="content" v-if="$route.params.language === 'en'">

          <p class="">By combining a wide variety of yarns, weaving organizations, and discerning processing variations, we create textile collections with rich look and surface. In these years, also during the Covid19 pandemic, still raging all over the world, we are making great efforts to develop eco-materials such as reused and recycled materials in order to respond to changes in values for fashion.</p>
  <h5 class="mt-12"><span class="lowercase font-script">“Le&nbsp;Tinte”</span> will continue to provide outstanding, sophisticated collections to every customer in the fashion-industry.</h5>
        </div>
        <div class="content" v-if="$route.params.language === 'it'">

    <p class=""> In questo momento, anche durante la pandemia che sta continuando ad imperversare in tutto il mondo, stiamo ponendo una sempre maggiore attenzione verso i temi della sostenibilità, assumendo scelte in grado di abbassare l’impatto ambientale delle attività produttive, contenere i consumi, progettare e realizzare stoffe che, per le materie prime usate, le modalità con cui sono state trattate, il comportamento a fine vita, diminuiscano i danni al nostro pianeta.
  </p>
  <h5 class="mt-12"><span class="lowercase font-script">“Le&nbsp;Tinte”</span> continuerà a fornire tessuti sofisticati e di qualità a tutti i clienti nell’industria della moda.</h5>
        </div>
        <div class="content" v-if="$route.params.language === 'jp'">

          <p class="">バラエティに富んだ糸、多彩な織組織とデザイン、そしてこだわりの加工バリエーションを幾通りにも組み合わせることにより、豊かな表情と表面感を持ったテキスタイルコレクションに仕上げています。一方で、未曾有のパンデミックを引き起こしたCOVID19が世界の至る所で猛威をふるい続ける中、ファッションに対する価値観の変化に対応すべく、リユース、リサイクル原料等のエコ素材の開発にも大きく力を入れています。</p>
  <h5 class="mt-12"><span class="lowercase font-script">Le Tinte</span> は、これからもファッション業界において、卓越し、洗練されたコレクションを皆さまに提供します。</h5>
        </div>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import bgImage from '@/assets/img/Letinte1.jpg'
import bgImage2 from '@/assets/img/Letinte71.jpg'

export default {
  data () {
    return {
      bgImage,
      bgImage2,
      text: '洗練'
    }
  }
}
</script>

<style lang="scss" scoped>
.identity {
  width: 90%;
  margin: auto;

  @include breakpoint('sm-and-down') {
    width: 100%;
  }
}

.text-align-right {
  @include breakpoint('sm-and-down') {
    text-align: left;
  }
}
</style>
